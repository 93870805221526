var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_amortization_list") } },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            "label-align": "left",
            "wrapper-col": { span: 12 },
            "label-col": { span: 9 },
            "data-testid": "amortization-list-filter-form"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_branch") } },
                    [
                      _c("SelectBranch", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid": "amortization-list-filter-branch"
                        },
                        model: {
                          value: _vm.filters.branch,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "branch", $$v)
                          },
                          expression: "filters.branch"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_lessor_name") } },
                    [
                      _c("SelectLessor", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid": "amortization-list-filter-lessor"
                        },
                        model: {
                          value: _vm.filters.lessor,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "lessor", $$v)
                          },
                          expression: "filters.lessor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_transaction_type") } },
                    [
                      _c("SelectAmortizationTransactionType", {
                        attrs: {
                          "data-testid":
                            "amortization-list-filter-transaction-type",
                          "label-in-value": ""
                        },
                        model: {
                          value: _vm.filters.transactionType,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "transactionType", $$v)
                          },
                          expression: "filters.transactionType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_amortization_number") } },
                    [
                      _c("SelectAmortization", {
                        attrs: {
                          "data-testid": "amortization-list-filter-number",
                          "label-in-value": ""
                        },
                        model: {
                          value: _vm.filters.amortization,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "amortization", $$v)
                          },
                          expression: "filters.amortization"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_status") } },
                    [
                      _c("SelectAmortizationStatus", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid": "amortization-list-filter-status"
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_document_number") } },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("lbl_document_number"),
                          "data-testid":
                            "amortization-list-filter-document-number"
                        },
                        model: {
                          value: _vm.filters.documentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "documentNumber", $$v)
                          },
                          expression: "filters.documentNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_leasing_number") } },
                    [
                      _c("SelectLeasing", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid": "amortization-list-filter-leasing"
                        },
                        model: {
                          value: _vm.filters.leasingNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "leasingNumber", $$v)
                          },
                          expression: "filters.leasingNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_leasing_contract_number") } },
                    [
                      _c("SelectLeasing", {
                        attrs: {
                          "label-in-value": "",
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_leasing_contract_number")
                          }),
                          "data-testid":
                            "amortization-list-filter-contract-number",
                          accessor: "leasingContractNumber"
                        },
                        model: {
                          value: _vm.filters.leasingContractNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "leasingContractNumber", $$v)
                          },
                          expression: "filters.leasingContractNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_period") } },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "data-testid": "amortization-list-filter-period"
                        },
                        model: {
                          value: _vm.filters.period,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    "data-testid":
                                      "amortization-list-filter-btn-reset",
                                    loading: _vm.loading.find
                                  },
                                  on: { click: _vm.handleResetFilter }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    "data-testid":
                                      "amortization-list-filter-btn-find",
                                    loading: _vm.loading.find
                                  },
                                  on: { click: _vm.handleFind }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_find")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.$can("create", "amortization")
                        ? _c(
                            "a-col",
                            { attrs: { span: 12, align: "end" } },
                            [
                              _c(
                                "RouterLink",
                                {
                                  attrs: {
                                    to: { name: "amortizations.create" }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        "data-testid":
                                          "amortization-list-filter-btn-create-new"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("lbl_create_new")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      size: "small",
                      "data-source": _vm.dataSource.data,
                      columns: _vm.columns,
                      "row-key": "amortizationId",
                      loading: _vm.loading.find,
                      pagination: {
                        showTotal: function() {
                          return _vm.$t("lbl_total_items", {
                            total: _vm.dataSource.totalElements
                          })
                        },
                        total: _vm.dataSource.totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                        current: _vm.pagination.page,
                        pageSize: _vm.pagination.limit
                      },
                      scroll: { x: 2300 },
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      "data-testid": "amortization-list-table"
                    },
                    on: { change: _vm.onChangeTable },
                    scopedSlots: _vm._u([
                      {
                        key: "cashOutNumber",
                        fn: function(_, row) {
                          return [
                            !!row.cashOutSecureId
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name:
                                          "cash-management.cash-in-out.edit",
                                        params: {
                                          id: row.cashOutSecureId
                                        }
                                      },
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(row.cashOutNumber) + " "
                                    )
                                  ]
                                )
                              : _c("span", [_vm._v(" " + _vm._s("-") + " ")])
                          ]
                        }
                      },
                      {
                        key: "action",
                        fn: function(_, row) {
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "amortizations.detail",
                                    params: {
                                      id: row.amortizationId
                                    }
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_view")) + " ")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "data-testid": "amortization-list-btn-download",
                        type: "primary",
                        loading: _vm.loading.download
                      },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_download")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
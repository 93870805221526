

















import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AmortizationResponseDto } from "@/models/interface/amortization";
import { amortizationService } from "@/services/amortization.service";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectAmortization extends Vue {
  @Prop({ required: false, default: undefined })
  value!: string | LabelInValue | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  options: Option<AmortizationResponseDto>[] = [];

  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = new RequestQueryParams();
    params.search = this.buildSearch();
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    this.loading = true;
    amortizationService
      .getAmortizations(params)
      .then(({ data }) => {
        this.options = data.map(amortization => {
          return {
            key: amortization.amortizationId,
            label: amortization.amortizationNumber,
            value: amortization.amortizationId,
            meta: amortization,
          };
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  buildSearch(arg?: { amortizationNumber?: string }): string {
    const builder = new SearchBuilder();
    const criteria: string = builder
      .push(["amortizationNumber", "null"], { not: true })
      .build();
    const queries: string[] = [criteria];

    if (arg?.amortizationNumber) {
      queries.push(
        builder
          .push(["amortizationNumber", arg.amortizationNumber], {
            like: "both",
          })
          .build()
      );
    }

    return queries.join(SearchBuilder.AND);
  }

  onChange(e: string | LabelInValue): void {
    if (!e) {
      this.onSearch();
    }

    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val?: string): void {
    const params = new RequestQueryParams();
    params.search = this.buildSearch({ amortizationNumber: val });

    this.fetchOptions(params);
  }

  findOption(
    value?: string | LabelInValue
  ): Option<AmortizationResponseDto> | undefined {
    if (!value) {
      return;
    }

    if (typeof value === "string") {
      return this.options.find(e => value === e.value);
    } else {
      return this.options.find(e => value.key === e.key);
    }
  }
}
